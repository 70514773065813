<template>
  <div class="research">
    <div class="wrap" v-if="windowWidth">
      <div class="introduce">
        <p class="title">
          <span class="center">关于PCG</span>
        </p>
        <p class="pcg_content">
          <span
            >腾讯PCG（Platform and Content
            Group，平台与内容事业群）是一个集社交、流量和内容平台于一体的大型事业群，业务需求覆盖了人工智能和大数据方面几乎所有的技术</span
          >
        </p>
      </div>
      <div class="media">
        <div class="text_line"></div>
        <div class="media_left">
          <p class="tit">Social Media</p>
          <p class="cont">带来引领潮流的独特社交体验</p>
        </div>
        <div class="media_right">
          <figure>
            <img src="../../../assets/img/qq.png" />
            <p>QQ</p>
          </figure>
          <figure>
            <img src="../../../assets/img/qq_space.png" />
            <p>QQ空间</p>
          </figure>
        </div>
      </div>
      <div class="content">
        <div class="text_line"></div>
        <div class="content_left">
          <p class="tit">Content Platform</p>
          <p class="cont">创造跨平台的数字内容消费体验</p>
        </div>
        <div class="content_right">
          <div class="line">
            <figure>
              <img src="../../../assets/img/tx_media.png" />
              <p>腾讯视频</p>
            </figure>
            <figure>
              <img src="../../../assets/img/ws.png" />
              <p>微视</p>
            </figure>
            <figure>
              <img src="../../../assets/img/tx_movie.png" />
              <p>腾讯影业</p>
            </figure>
            <figure>
              <img src="../../../assets/img/qe_movie.png" />
              <p>企鹅影视</p>
            </figure>
            <figure class="tx_text">
              <img src="../../../assets/img/tx_text.png" />
              <p>腾讯看点</p>
            </figure>
          </div>
          <div class="line">
            <figure class="news">
              <img src="../../../assets/img/news.png" />
              <p>快报</p>
            </figure>
            <figure class="tx_news">
              <img src="../../../assets/img/tx_news.png" />
              <p>腾讯新闻</p>
            </figure>
            <figure class="tx_sport">
              <img src="../../../assets/img/tx_sport.png" />
              <p>腾讯体育</p>
            </figure>
            <figure class="tx_dm" style="display: block">
              <img src="../../../assets/img/tx_dm.png" />
              <p>腾讯动漫</p>
            </figure>
          </div>
        </div>
      </div>
      <div class="app">
        <div class="text_line"></div>
        <div class="app_left">
          <p class="tit">App Store and Browser</p>
          <p class="cont">实现内容/产品最有效的用户触达</p>
        </div>
        <div class="app_right">
          <figure>
            <img src="../../../assets/img/tx_app.png" />
            <p>腾讯应用宝</p>
          </figure>
          <figure>
            <img src="../../../assets/img/qq_browser.png" />
            <p>QQ浏览器</p>
          </figure>
        </div>
      </div>
      <div class="issues">
        <p class="tit">相关的问题空间</p>
        <div class="img_box">
          <img class="svg_1" src="../../../assets/img/diversity.svg" alt="" />
          <img
            class="svg_2"
            src="../../../assets/img/transformation.svg"
            alt=""
          />
          <img
            class="svg_3"
            src="../../../assets/img/dataAnalysis.svg"
            alt=""
          />
        </div>
      </div>
      <div class="demonstration">
        <p class="tit">技术演示</p>
        <div class="video_box">
          <el-carousel
            height="3.6rem"
            trigger="click"
            :autoplay="false"
            @change="getIndex"
          >
            <el-carousel-item v-for="(item, index) in videoUrl" :key="index">
              <h3 class="small">
                <video
                  preload="auto"
                  :ref="'videoPlayer' + index"
                  controls
                  :src="item.url + '#t=1'"
                  :poster="item.poster"
                >
                  Your browser does not support the video tag.
                </video>
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img_box">
          <div>
            <img src="../../../assets/img/blog1.png" alt="" />
            <img class="center" src="../../../assets/img/blog2.png" alt="" />
            <img src="../../../assets/img/blog3.png" alt="" />
          </div>
          <div>
            <img src="../../../assets/img/blog4.png" alt="" />
            <img class="center" src="../../../assets/img/blog5.png" alt="" />
            <img src="../../../assets/img/blog6.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="wraps" v-else>
      <div class="introduce">
        <p class="title">
          <span class="center">关于PCG</span>
        </p>
        <p class="pcg_content">
          <span
            >腾讯PCG（Platform and Content
            Group，平台与内容事业群）是一个集社交、流量和内容平台于一体的大型事业群，业务需求覆盖了人工智能和大数据方面几乎所有的技术</span
          >
        </p>
      </div>
      <div class="media">
        <div class="text_line"></div>
        <div class="media_left">
          <p class="tit">Social Media</p>
          <p class="cont">带来引领潮流的独特社交体验</p>
        </div>
        <div class="media_right">
          <figure>
            <img src="../../../assets/img/qq.png" />
            <p>QQ</p>
          </figure>
          <figure>
            <img src="../../../assets/img/qq_space.png" />
            <p>QQ空间</p>
          </figure>
        </div>
      </div>
      <div class="content">
        <div class="text_line"></div>
        <div class="content_left">
          <p class="tit">Content Platform</p>
          <p class="cont">创造跨平台的数字内容消费体验</p>
        </div>
        <div class="content_right">
          <div class="figBox">
            <img src="../../../assets/img/tx_media.png" />
            <p>腾讯视频</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/ws.png" />
            <p>微视</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/tx_movie.png" />
            <p>腾讯影业</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/qe_movie.png" />
            <p>企鹅影视</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/tx_text.png" />
            <p>腾讯看点</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/news.png" />
            <p>快报</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/tx_news.png" />
            <p>腾讯新闻</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/tx_sport.png" />
            <p>腾讯体育</p>
          </div>
          <div class="figBox">
            <img src="../../../assets/img/tx_dm.png" />
            <p>腾讯动漫</p>
          </div>

          <div class="line"></div>
        </div>
      </div>
      <div class="app">
        <div class="text_line"></div>
        <div class="app_left">
          <p class="tit">App Store and Browser</p>
          <p class="cont">实现内容/产品最有效的用户触达</p>
        </div>
        <div class="app_right">
          <div>
            <img src="../../../assets/img/tx_app.png" />
            <p>腾讯应用宝</p>
          </div>
          <div>
            <img src="../../../assets/img/qq_browser.png" />
            <p>QQ浏览器</p>
          </div>
        </div>
      </div>
      <div class="issues">
        <p class="tit">相关的问题空间</p>
        <div class="img_box">
          <img class="svg_1" src="../../../assets/img/diversity.svg" alt="" />
          <img src="../../../assets/img/arrow.svg" alt="" />

          <img
            class="svg_3"
            src="../../../assets/img/dataAnalysis.svg"
            alt=""
          />
        </div>
      </div>
      <div class="demonstration">
        <p class="tit">技术演示</p>
        <div class="video_box" style="height: 3.9rem">
          <Carousel
            class="my-swipe"
            v-model="value3"
            :autoplay="setting.autoplay"
            :autoplay-speed="setting.autoplaySpeed"
            :dots="setting.dots"
            :trigger="setting.trigger"
            :arrow="setting.arrow"
            @on-change="changeItem"
          >
            <Carousel-item v-for="(item, index) in videoUrl" :key="index">
              <video
                controls
                :src="item.url"
                :id="item.index"
                class="vid"
                :poster="item.poster"
              >
                Your browser does not support the video tag.
              </video>
            </Carousel-item>
          </Carousel>
        </div>
        <div class="result_box">
          <img
            src="../../../assets/img/blog1.png"
            alt=""
            style="margin-right: 0.3rem"
          />
          <img class="center" src="../../../assets/img/blog2.png" alt="" />
          <img
            src="../../../assets/img/blog3.png"
            alt=""
            style="margin-right: 0.3rem"
          />

          <img src="../../../assets/img/blog4.png" alt="" />
          <img
            class="center"
            src="../../../assets/img/blog5.png"
            alt=""
            style="margin-right: 0.3rem"
          />
          <img src="../../../assets/img/blog6.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },

  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
    bus.$emit("textBybus", this.text);
  },
  data() {
    return {
      windowWidth: true,
      bannerHeight: 400,
      text: "研究领域",
      controls: false,
      pause: true,
      nowIndex: 0,
      oldIndex_phone: 0,
      auto: true,
      auto_phone: "false",
      value3: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: "inside",
        trigger: "click",
        arrow: "always",
      },
      videoUrl: [
        {
          index: 0,
          poster:
            "http://50058.gzc.svp.tencent-cloud.com/0b53nualmaaauiapnno5gzqiu3odwzwqbnqa.f0.jpg?dis_k=7f74a94ffa772bf191e734ef32d3ab8b&dis_t=1625112783",
          url: "http://50058.gzc.svp.tencent-cloud.com/0b53fic7gaaf24am47oruvqi6kwd6mval4ya.f0.MP4?dis_k=eab072442ae8c957c367d4c5856bd1dc&dis_t=1624522253",
        },
        {
          index: 1,
          poster:
            "http://50058.gzc.svp.tencent-cloud.com/0b533ubh4aacj4ad4r67cbqirxodp3oqe7qa.f0.jpg?dis_k=9b9df9ee9c2f66e7f1f9f2ba831ea595&dis_t=1625112723",
          url: "http://50058.gzc.svp.tencent-cloud.com/0b536mad6aaatqap7twcm5qiv46dh7zqapya.f0.mp4?dis_k=b406ba9cc5b125c674b0e0203681faf5&dis_t=1623376004",
        },
        {
          index: 2,
          url: "http://50058.gzc.svp.tencent-cloud.com/0b53uecbqaaeb4am6v5ia5qi5ioddcqqigaa.f0.mp4?dis_k=e744a3540acbf64c1f1b83a88db7595d&dis_t=1621940143",
          poster:
            "http://50058.gzc.svp.tencent-cloud.com/0b53j4ar6aab5maf7sw4njqiwt6dd5hqchya.f0.jpg?dis_k=52837d58ebf69b75ad0adc79219a8ca5&dis_t=1625112740",
        },
        {
          index: 3,
          poster:
            "http://50058.gzc.svp.tencent-cloud.com/0b5374ar4aabeiaf5i64nbqix76dd37qchqa.f0.jpg?dis_k=4c96a302a068963e68ec06ae3c6ec09c&dis_t=1625112797",
          url: "http://50058.gzc.svp.tencent-cloud.com/0b53yucbmaae7uam4mvibfqi5rodc3cqifqa.f0.mp4?dis_k=7a2f6608b5da64a8bbb1e96d31a5388c&dis_t=1621940280",
        },
        {
          index: 4,
          poster:
            "http://50058.gzc.svp.tencent-cloud.com/0b53cmar4aab7eaf4564nfqiwe6ddyjqchqa.f0.jpg?dis_k=7124b64681fbc4acc884390e1434425f&dis_t=1625112811",
          url: "http://50058.gzc.svp.tencent-cloud.com/0b53cmauyaableaeswvworqiwe6djqjqctaa.f0.mp4?dis_k=cbc389114023b563a2b7889c31bbbb4b&dis_t=1622607178",
        },
      ],
    };
  },

  methods: {
    changeItem(item) {
      var vid = document.getElementById(item);
      vid.pause();
      //var idx = e.target.id ? e.target.id : e.target.dataset.id;
    },
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    getIndex(nowIndex, oldIndex) {
      let myVideo = this.$refs["videoPlayer" + oldIndex];
      myVideo[0].pause();
      myVideo[0].currentTime = 0;
      this.nowIndex = nowIndex;
      this.listenVideo();
    },
    getIndex_phone(index) {
      let myVideo = this.$refs["videoPlayer_phone" + this.oldIndex_phone];
      myVideo[0].pause();
      myVideo[0].currentTime = 0;
      this.oldIndex_phone = index;
      this.listenVideo_phone(index);
    },
    //监听视频的播放、暂停、播放完毕等事件
    listenVideo() {
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      // 监听播放
      myVideo[0].addEventListener("play", () => {
        this.auto = false;
      });
      // 监听暂停
      myVideo[0].addEventListener("pause", () => {
        this.auto = true;
      });
      // 监听播放完毕
      myVideo[0].addEventListener("ended", () => {
        this.auto = true;
      });
    },
    listenVideo_phone(index) {
      let myVideo = this.$refs["videoPlayer_phone" + index];
      // 监听播放
      myVideo[0].addEventListener("play", () => {
        this.auto_phone = "false";
      });
      // 监听暂停
      myVideo[0].addEventListener("pause", () => {
        this.auto_phone = "3000";
      });
      // 监听播放完毕
      myVideo[0].addEventListener("ended", () => {
        this.auto_phone = "3000";
      });
    },
  },
};
</script>

<style lang="less" scoped>
.research {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    .introduce {
      margin-top: 0.6rem;
      .title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        margin-bottom: 0.15rem;
        .center {
          position: relative;
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222;
        }
      }
      .pcg_content {
        text-align: center;
        span {
          display: inline-block;
          min-height: 0.32rem;
          width: 8.02rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
          text-align: center;
        }
      }
    }
    .media,
    .content,
    .app {
      overflow: hidden;
      margin-top: 0.4rem;
    }
    .text_line,
    .media_left,
    .media_right,
    .content_left,
    .content_right,
    .app_left,
    .app_right {
      float: left;
    }
    .text_line {
      width: 0.05rem;
      height: 0.64rem;
      background: #2e54eb;
      border-radius: 1px;
      margin-right: 0.2rem;
      margin-top: 0.06rem;
    }
    .media_left,
    .content_left,
    .app_left {
      .tit {
        height: 0.4rem;
        line-height: 0.4rem;
        font-family: PingFangSC-Semibold, sans-serif;
        font-size: 0.28rem;
        color: #222222;
      }
      .cont {
        height: 0.35rem;
        margin-top: 0.05rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.16rem;
        color: #666666;
        line-height: 0.32rem;
      }
    }
    .media_left {
      margin-right: 0.8rem;
      .cont {
        width: 3.31rem;
      }
    }
    .content_left {
      margin-right: 0.8rem;
      .cont {
        width: 3.31rem;
      }
    }
    .app_left {
      margin-right: 0.8rem;
      .cont {
        width: 3.31rem;
      }
    }
    .media_right,
    .content_right,
    .app_right {
      overflow: hidden;
      max-width: 7.4rem;
      .line {
        overflow: hidden;
      }
      figure {
        float: left;
        margin-right: 0.4rem;
        text-align: center;
        img {
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -webkit-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -moz-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -o-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
        }
        p {
          width: 1rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #222222;
          text-align: center;
        }
      }
      .tx_text {
        margin-right: 0rem;
      }
      .news,
      .tx_news,
      .tx_sport,
      .tx_dm {
        margin-top: 0.3rem;
      }
    }
    .issues {
      margin-top: 0.8rem;
      .tit {
        height: 0.5rem;
        line-height: 0.5rem;
        font-family: PingFangSC-Semibold, sans-serif;
        font-size: 0.36rem;
        color: #222222;
        text-align: center;
      }
      .img_box {
        height: 4.5rem;
        margin-top: 0.4rem;
        position: relative;
        .svg_1,
        .svg_3 {
          width: 4.5rem;
          height: 4.5rem;
          position: absolute;
          top: 0;
        }
        .svg_1 {
          left: 0.13rem;
        }
        .svg_3 {
          right: 0.13rem;
        }
        .svg_2 {
          width: 0.73rem;
          height: 0.5rem;
          position: absolute;
          right: 5.55rem;
          top: 1.75rem;
        }
      }
    }
    .demonstration {
      margin-top: 0.8rem;
      .tit {
        height: 0.5rem;
        line-height: 0.5rem;
        font-family: PingFangSC-Semibold, sans-serif;
        font-size: 0.36rem;
        color: #222222;
        text-align: center;
      }
      .video_box {
        width: 6.4rem;
        height: 3.6rem;
        margin: 0.4rem auto 0.6rem;
        position: relative;
        video {
          width: 100%;
          height: 3.6rem;
          min-width: 100%;
        }
        video:focus {
          outline: none;
        }
        .poster_box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #19103d;
          opacity: 0.72;
          .poster {
            width: 100%;
            height: 86%;
            position: absolute;
            top: 14%;
            left: 0;
          }
        }
        .chevron {
          width: 0.36rem;
          height: 0.58rem;
          position: absolute;
          left: 3.06rem;
          top: 1.45rem;
          cursor: pointer;
        }
      }
      .img_box {
        margin-bottom: 0.6rem;
        div {
          text-align: center;
          margin-bottom: 0.4rem;
          img {
            width: 3.73rem;
            height: 2.1rem;
          }
          .center {
            margin: 0 0.4rem;
          }
        }
      }
    }
  }
  .wraps {
    .tit {
      text-align: center;
      height: 0.67rem;
      font-family: PingFangSC-Semibold;
      font-size: 0.48rem;
      color: #222222;
      margin-bottom: 0.22rem;
    }
    .cont {
      height: 0.52rem;
      font-family: PingFangSC-Regular;
      font-size: 0.28rem;
      color: #666666;
      line-height: 0.52rem;
      text-align: center;
    }
    .introduce {
      margin-top: 1rem;
      text-align: center;
      .title {
        .center {
          display: inline-block;
          margin-bottom: 0.22rem;
          height: 0.67rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.48rem;
          color: #222222;
        }
      }
      .pcg_content {
        margin: 0 auto;
        span {
          display: inline-block;
          width: 5.67rem;
          font-family: PingFangSC-Regular;
          font-size: 0.28rem;
          color: #666666;
          text-align: center;
          line-height: 0.52rem;
        }
      }
    }
    .media {
      margin-top: 1rem;
      .media_left {
        text-align: center;
      }
    }
    .media_right {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;

      figure {
        width: 50%;
        text-align: center;
        display: inline-block;
        img {
          box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -o-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
        }
        p {
          margin-top: 0.4rem;
          line-height: 0.45rem;
          font-family: PingFangSC-Regular;
          font-size: 0.32rem;
          color: #222222;
          text-align: center;
        }
      }
    }
    .content {
      margin-top: 1rem;
    }
    .content_left {
      text-align: center;
    }
    .content_right {
      margin-top: 0.6rem;

      .figBox {
        display: inline-block;
        margin-bottom: 0.6rem;
        width: 50%;
        text-align: center;

        p {
          margin-top: 0.4rem;
          line-height: 0.45rem;
          font-family: PingFangSC-Regular;
          font-size: 0.32rem;
          color: #222222;
          text-align: center;
        }
        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -o-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
        }
      }
    }
    .app {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .app_right {
      margin-top: 0.6rem;
      div {
        width: 50%;
        display: inline-block;
        text-align: center;
        p {
          margin-top: 0.4rem;
          height: 0.45rem;
          font-family: PingFangSC-Regular;
          font-size: 0.32rem;
          color: #222222;
          text-align: center;
        }
        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
          -o-box-shadow: 0 0 0.16rem 0 rgba(0, 0, 0, 0.08);
        }
      }
    }
    .issues {
      .tit {
        margin-bottom: 0px;
      }
      .img_box {
        text-align: center;
      }
    }
    .img_box img:nth-child(1) {
      margin-top: 0.6rem;
      width: 5.9rem;
      height: 5.9rem;
    }
    .img_box img:nth-child(2) {
      margin-top: 0.24rem;
      display: block;
      width: 0.7rem;
      height: 0.62rem;
      margin: 0 auto;
    }
    .img_box img:nth-child(3) {
      margin-top: 0.24rem;
      width: 5.9rem;
      height: 5.9rem;
    }
    .demonstration {
      margin-bottom: 0.7rem;
      .tit {
        margin-top: 1rem;
        margin-bottom: 0.6rem;
      }
      .video_box {
        width: 85.3%;
        margin: 0 auto;
        .my-swipe {
          width: 100%;
          height: 100%;
          video {
            width: 100%;
            height: 100%;
          }
          .van-swipe-item {
            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .result_box {
        margin-top: 0.6rem;
        padding: 0px 0.55rem;
        img {
          margin-bottom: 0.3rem;
          width: 47.5%;
          height: 1.71rem;
        }
      }
    }
  }
}
</style>
